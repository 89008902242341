import classNames from "classnames";
import { useRouter } from "next/router";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import BaseLayout, { useLayout } from "../components/BaseLayout/BaseLayout";
import Button from "../components/Button/Button";
import { useCurrencyConverter } from "../components/CurrencyConverterProvider";
import CurrencyExchangeIndicator from "../components/CurrencyExchangeIndicator/CurrencyExchangeIndicator";
import Checkbox from "../components/FormElements/Checkbox";
import VMNumberInput from "../components/FormElements/VMNumberInput";
import NFTCard from "../components/NFTCard/NFTCard";
import Spinner from "../components/UIElements/Spinner";
import { useBreakpoints } from "../components/util/mobileBreakpointHooks";
import useUnitPrice from "../components/util/unitPriceMock";
import { useWallet } from "../components/WalletProvider";
import Bitcoin from "../public/assets/svg/btc.svg";
import {
  FeedbackMessagesManager,
  useFeedbackMessagesFunctions,
} from "../components/FormElements/FeedbackMessagesManager";
import { createCheckoutSession, mintFreeMiner } from "../services/reactor/api";
import WaitlistSignup from "../components/FormElements/WaitlistSignup";
import FormatMinerDuration from "../components/util/FormatMinerDuration";
import HellosignModal from "../components/FormElements/HellosignModal";
import { loadStripe } from "@stripe/stripe-js";
import Testimonial from "../components/Testimonial/Testimonial";

const pageTitle = "Start mining Bitcoin in minutes.";

const MintingPage = () => {
  const router = useRouter();
  const { is1024OrSmaller } = useBreakpoints();
  const {
    walletName,
    tokens,
    inventory,
    walletConnected,
    initializing,
    jwtToken,
    hasTokens,
    tokenCount,
    trial_miner_activated,
    refreshAccount,
    email,
    is_agreement_signed,
  } = useWallet();
  const { addMessage, removeMessage } = useFeedbackMessagesFunctions();
  const {
    BTCExchangeRate,
    converterError,
    loading,
    setCurrencyConverterActive,
  } = useCurrencyConverter();

  const { openWalletConnectionModal, openFiatModal, resetLayout } = useLayout();

  const { unitPriceError } = useUnitPrice(); // currently mocks NFT unit prices and errors

  const [pricePerMiner, setPricePerMiner] = useState(0);
  const [hashratePerMiner, setHashratePerMiner] = useState(0);
  const [isMintingEnabled, setIsMintingEnabled] = useState(true);
  const [minerDuration, setMinerDuration] = useState(0);
  const [globalLimit, setGlobalLimit] = useState(0);
  const [minerName, setMinerName] = useState("Reactor Miner");

  const [stripeUrl, setStripeUrl] = useState<URL>();

  useEffect(() => {
    if (!walletConnected) {
      setMinting(false);
      setLoad(false);
    }
    setMinerName("Reactor Miner");
    setPricePerMiner(inventory.per_miner_price / 100);

    setHashratePerMiner(inventory.per_miner_hashrate);
    setIsMintingEnabled(inventory.available);
    setMinerDuration(inventory.per_miner_duration);
    setGlobalLimit(
      Math.floor(inventory.available_terahashes / inventory.per_miner_hashrate),
    );
  }, [walletName, inventory, tokenCount, walletConnected]);

  const canMint = initializing || isMintingEnabled;

  const [load, setLoad] = useState<boolean>(false);

  const [numberOfVMs, setNumberOfVMs] = useState<number>(1);

  const [isAgreed, setIsAgreed] = useState<boolean>(false);

  const [minting, setMinting] = useState<boolean>(false);
  const [successfulMint, setSuccessfulMint] = useState<boolean>(false);
  const [hellosignModal, setHellosignModal] = useState(false);

  const testimonials = [
    [
      "If I could trade in all of my colocated miners in for Reactors, I would do so in a heartbeat.",
      "Heath L.",
    ],
    ["A fantastic way to accumulate Bitcoin.", "Seth S."],
    [
      "It's a heck of lot easier than buying, installing and maintaining rigs.",
      "Rick S.",
    ],
    [
      "I can mine bitcoin with a few clicks - and the system really works!",
      "Bryan B.",
    ],
  ];
  const testimonialsIdx = Math.floor(Math.random() * testimonials.length);

  useEffect(() => {
    if (minting) {
      setLoad(true);
      addMessage({
        msg: "Stripe not opening? If you are stuck on this page, please reload and try again. If stripe is open, you may safely close this page.",
        status: "pending",
        autoClear: 0,
      });
    }
  }, [minting, addMessage]);

  const handleCheckout = async () => {
    setLoad(true);

    if (!is_agreement_signed) {
      setHellosignModal(true);
    } else openStripeModal();
  };

  const openStripeModal = useCallback(async () => {
    setMinting(true);

    // load stripe
    const stripePromise = await loadStripe(
      String(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY),
    );

    createCheckoutSession({
      quantity: numberOfVMs,
      hashrate: hashratePerMiner,
      duration: minerDuration,
      successUrl: `${window.location.origin}/purchasing`,
      cancelUrl: `${window.location.origin}/`,
      jwtToken,
    })
      .then((checkout) => {
        if (checkout.status === "success") {
          setStripeUrl(checkout.url);
          window.location.href = String(checkout.url);
        }
      })
      .catch((e) => {
        addMessage({
          msg: e.message ? e.message : "Stripe Failed to Load",
          status: "failed",
        });
      });
  }, [hashratePerMiner, jwtToken, minerDuration, numberOfVMs, addMessage]);

  useEffect(() => {
    // dont refresh unnecessarily when logged out
    if (!walletConnected) return;

    // if the user is signed with hellosign open, close it and open stripe
    if (is_agreement_signed && hellosignModal) {
      setHellosignModal(false);
      resetLayout();
      removeMessage("Getting document signed status");
      openStripeModal();
    } else if (is_agreement_signed) return; // otherwise if they are signed just skip this

    const timer = setTimeout(() => {
      // if the user isn't signed, check until they are
      refreshAccount();
    }, 5000);
    return () => clearTimeout(timer);
  }, [
    removeMessage,
    refreshAccount,
    openStripeModal,
    resetLayout,
    is_agreement_signed,
    hellosignModal,
    walletConnected,
  ]);

  // Stripe functions END

  const buttonDisabled =
    load ||
    !isAgreed ||
    numberOfVMs === 0 ||
    successfulMint ||
    minting ||
    globalLimit === 0;

  const freeMiner = async () => {
    setMinting(true);
    setLoad(true);

    try {
      // Await either the mintFreeMiner or timeoutPromise to finish
      const freeMintOut = await mintFreeMiner({ jwtToken });

      if (freeMintOut.status === "success") {
        addMessage({
          msg: `Miner Created!`,
          status: "success",
        });
        setSuccessfulMint(true);
        router.push("/config");
      } else {
        throw new Error("Free Mint Failed");
      }
    } catch (error) {
      addMessage({
        msg: "Free miner failed. Refresh the page and try again.",
        status: "failed",
      });
    } finally {
      await refreshAccount();
      setMinting(false);
      setLoad(false);
    }
  };

  useEffect(() => {
    setCurrencyConverterActive(true);
    return () => setCurrencyConverterActive(false);
  }, [setCurrencyConverterActive]);

  const mintForFreeButton = (
    <div className="relative inline-block w-full mt-2 px-8">
      <Button
        variant="secondary"
        fullWidth
        disabled={buttonDisabled}
        className="mb-2.5"
        onClick={(e) => {
          e.preventDefault();
          if (trial_miner_activated) {
            addMessage({
              msg: "Free trial already activated",
              status: "failed",
            });
            return;
          }
          if (isAgreed) {
            freeMiner();
          }
        }}
      >
        {successfulMint ? (
          "Miner Created!"
        ) : load ? (
          <Spinner />
        ) : (
          `Activate Free Trial: ${
            inventory.trial_miner_hashrate
          }TH/s for ${FormatMinerDuration(inventory.trial_miner_duration)}`
        )}
      </Button>
      {!isAgreed && (
        <div
          onClick={() => {
            addMessage({
              msg: "Please agree to our terms to continue",
              status: "failed",
            });
          }}
          className="absolute top-0 left-0 right-0 bottom-0 cursor-pointer"
        />
      )}
    </div>
  );

  const connectWalletButton = (
    <div className="px-8">
      <Button
        fullWidth
        onClick={(e) => {
          e.preventDefault();
          openWalletConnectionModal();
        }}
        className="mb-2.5"
      >
        Start Mining{" "}
        <Bitcoin className="transition duration-300 hover:-translate-y-1 inline mb-[2px]" />{" "}
      </Button>
    </div>
  );

  const soldOutButton = (
    <div className="px-8">
      <Button
        fullWidth
        onClick={(e) => {
          e.preventDefault();
          openWalletConnectionModal();
        }}
        disabled={true}
        className="mb-2.5"
      >
        Sold Out{" "}
        <Bitcoin className="transition duration-300 hover:-translate-y-1 inline mb-[2px]" />{" "}
      </Button>
    </div>
  );

  const stripeButton = (
    <div className="px-8">
      <Button fullWidth onClick={handleCheckout} disabled={buttonDisabled}>
        {load ? (
          <Spinner />
        ) : (
          <>
            Buy{" "}
            <Bitcoin className="transition duration-300 hover:-translate-y-1 inline mb-[2px]" />{" "}
            Hashpower
          </>
        )}
      </Button>
    </div>
  );

  const disabledButton = (
    <div className="px-8">
      <Button fullWidth onClick={handleCheckout} disabled={buttonDisabled}>
        {load ? (
          <Spinner />
        ) : (
          <>
            <Bitcoin className="transition duration-300 hover:-translate-y-1 inline mb-[2px]" />
            {" Sold out"}
          </>
        )}
      </Button>
    </div>
  );

  const actionButtons = walletConnected ? (
    stripeUrl ? (
      <>
        If stripe doesn&apos;t open automatically, please click{" "}
        <a
          target={"_self"}
          rel="noreferrer"
          href={String(stripeUrl)}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          here
        </a>
      </>
    ) : (
      <>
        {isMintingEnabled ? <>{stripeButton}</> : <>{disabledButton}</>}
        {!trial_miner_activated && inventory.available && mintForFreeButton}
      </>
    )
  ) : (
    <>
      <>{isMintingEnabled ? connectWalletButton : soldOutButton}</>
      <Testimonial
        quote={testimonials[testimonialsIdx][0]}
        author={testimonials[testimonialsIdx][1]}
      />
    </>
  );

  const agreementCheckbox = (
    <Checkbox
      disabled={load}
      name={""}
      label={
        <>
          I agree to Reactor&apos;s{" "}
          <a
            target={"_blank"}
            rel="noreferrer"
            href={"https://about.reactor.xyz/terms-of-use"}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            Terms of Use
          </a>{" "}
          and{" "}
          <a
            target={"_blank"}
            rel="noreferrer"
            href={"https://about.reactor.xyz/purchase-agreement"}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            Purchase Agreement
          </a>
        </>
      }
      checked={isAgreed}
      onChange={(e) => {
        setIsAgreed(e.target.checked);
      }}
    />
  );

  const nftCard = (
    <NFTCard
      NFTUrl={
        "https://storage.googleapis.com/nft-cards.reactor.mine.app/REACTOR-100th.mp4"
      }
      minerName={minerName}
      minerHashrate={hashratePerMiner}
      singleMinerPrice={pricePerMiner}
      // isMobile={is1024OrSmaller}
      isMobile={false}
      minerDuration={minerDuration}
      minerAlgorithm="SHA-256"
    />
  );

  const mintFormSubmitted = (e: any) => {
    e.preventDefault();
    if (numberOfVMs === 0) {
      addMessage({
        msg: "Please select an amount greater than 0.",
        status: "failed",
      });
    }

    if (
      numberOfVMs > 0 &&
      numberOfVMs <= globalLimit - tokens.length &&
      isAgreed
    ) {
      setMinting(true);
      setLoad(true);
      addMessage({
        msg: `Buying miners`,
        status: "pending",
      });
      console.log("minting miners");
    }
  };

  const vmNumberChanged = (target: number) => {
    if (target > globalLimit) {
      addMessage({
        msg: `There are only ${globalLimit} miners miners remaining at this time.`,
        status: "failed",
      });
      return;
    }
    if (target < 0) {
      addMessage({
        msg: `You may not enter an amount less than zero.`,
        status: "failed",
      });
      return;
    }
    setNumberOfVMs(target);
  };

  return (
    <>
      <div
        className={classNames(
          "flex mx-auto text-secondary-black-500 justify-center",
          hasTokens && !is1024OrSmaller && "pb-8",
          !hasTokens && (is1024OrSmaller ? "pb-2" : "mt-8 pb-16"),
          !is1024OrSmaller && "justify-between max-w-[960px]",
        )}
      >
        <title>Reactor by Navier</title>
        {hellosignModal && (
          <HellosignModal
            modalOpen={hellosignModal}
            onCloseModal={() => {
              setHellosignModal(false);
              resetLayout();
              if (!is_agreement_signed) setLoad(false); // if not continuing to check out
            }}
          />
        )}
        <div
          className={classNames(
            "flex flex-col justify-around w-full max-w-[432px] py-7 mb-auto",
            "space-y-10",
          )}
        >
          {is1024OrSmaller ? <h3>{pageTitle}</h3> : <h2>{pageTitle}</h2>}
          {is1024OrSmaller && nftCard}
          {!!email || !walletConnected ? (
            <>
              {/* BEGINNING OF FORM  */}
              <form
                className="flex flex-col space-y-10"
                onSubmit={mintFormSubmitted}
              >
                <div className="flex justify-between font-bold ">
                  <div className="flex-grow">
                    <VMNumberInput
                      disabled={load || !canMint}
                      onChange={({ target: { value } }) =>
                        vmNumberChanged(Number(value))
                      }
                      min={0}
                      max={globalLimit}
                      value={numberOfVMs}
                    />
                  </div>
                  <button
                    disabled={load}
                    type="button"
                    className={classNames(
                      "ml-3 font-bold hover:text-secondary-black-300",
                      load ? "text-grayscale-400" : "text-grayscale-500",
                    )}
                    onClick={() => {
                      if (globalLimit == 0)
                        addMessage({
                          msg: "You are at the maximum number of miners allocated per user at this time",
                          status: "failed",
                        });
                      setNumberOfVMs(globalLimit);
                    }}
                  >
                    MAX
                  </button>
                </div>
                <div className="flex justify-between border-b border-b-solid border-grayscale-300 pb-3.5">
                  <h5 style={{ alignSelf: "flex-end" }}>Total</h5>
                  <CurrencyExchangeIndicator
                    BTCExchangeRate={BTCExchangeRate}
                    converterError={!!converterError}
                    loading={loading}
                    unitPrice={pricePerMiner}
                    unitPriceError={!!unitPriceError} // TODO: update error logic based on fetching process
                    multiple={numberOfVMs}
                    textPrepend={`${numberOfVMs * hashratePerMiner} TH/s: `}
                  />
                </div>
                {walletConnected && agreementCheckbox}
                <div className="pt-2">{actionButtons}</div>
              </form>

              {/* END OF FORM */}
              <FeedbackMessagesManager />
            </>
          ) : (
            <>
              <div
                className={classNames(
                  "p-10 bg-white rounded-lg shadow-lg border border-grayscale-200 rounded-2xl",
                )}
              >
                <WaitlistSignup
                  title="Add Account Email"
                  text="Please give us an email to associate with your account"
                  successMessage="Thank you for signing up, happy mining!"
                  message={false}
                />
              </div>
            </>
          )}
        </div>
        {!is1024OrSmaller && (
          <div className="pl-10">
            <div className={classNames("flex flex-col w-[432px]")}>
              {nftCard}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default () => {
  const { hasTokens, initializing, is_admin, walletConnected } = useWallet();

  return (
    <BaseLayout
      withSidebar={
        walletConnected && ((!initializing && hasTokens) || is_admin)
      }
    >
      <MintingPage />
    </BaseLayout>
  );
};
